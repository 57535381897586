
import React, { useEffect } from 'react';
import { PlayIcon, PauseIcon, StopIcon } from "@radix-ui/react-icons";
import { Button } from '../ui/button';

interface TimerProps {
    seconds: number;
    setSeconds: React.Dispatch<React.SetStateAction<number>>;
    isActive: boolean;
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Timer: React.FC<TimerProps> = ({ seconds, setSeconds, isActive, setIsActive }) => {
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (isActive) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds + 1);
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval!);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isActive, seconds, setSeconds]);

    const toggle = () => {
        setIsActive(!isActive);
    };

    const reset = () => {
        setSeconds(0);
        setIsActive(false);
    };

    return (

        <>
            <div className='flex-1 text-2xl font-bold text-white'>{new Date(seconds * 1000).toISOString().substr(11, 8)}</div>
            <div className="flex space-x-2">
                <Button size="icon" onClick={toggle} className={`p-1 rounded-full ${isActive ? 'bg-yellow-400 hover:bg-yellow-500' : 'bg-green-400 hover:bg-green-500'} h-10 w-10 shrink-0 rounded-full `}>
                    {isActive ? <PauseIcon className="h-5 w-5 text-white" /> : <PlayIcon className="h-5 w-5 text-white" />}
                </Button>
                <Button size="icon" onClick={reset}  className="p-1 bg-red-400 hover:bg-red-500 h-10 w-10 shrink-0 rounded-full ml-2"><StopIcon className="h-5 w-5 text-white" /></Button>
            </div>
        </>

    );
};