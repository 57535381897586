import React, { useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FaSun, FaMoon, FaCloudSun } from 'react-icons/fa';
import { SwipeableList, SwipeableListItem, SwipeAction, TrailingActions } from 'react-swipeable-list';
import {deleteWorkout } from '../../features/workoutSlice';
import 'react-swipeable-list/dist/styles.css'; // Import the styles
import { format } from 'date-fns';

import { auth } from '../../firebase';

import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { Calendar } from '../ui/calendar';
import { ScrollArea } from '../ui/scroll-area';

import LoadingSpinner from '../custom/LoadingSpinner';

import { createWorkout, getWorkouts } from '../../features/workoutSlice';

const getTimeOfDayIcon = (date: Date) => {
    const hour = date.getHours();
    if (hour < 12) {
        return <FaSun className="text-white" />; // Morning
    } else if (hour < 18) {
        return <FaCloudSun className="text-white" />; // Afternoon
    } else {
        return <FaMoon className="text-white" />; // Night
    }
};

const Workouts: React.FC = () => {
    const queryClient = useQueryClient();
    const user = auth.currentUser;
    const { workoutID } = useParams();

    const [selectedDay, setSelectedDay] = useState<Date | undefined>(undefined);
    const workoutQuery = useQuery({
        queryKey: ["workouts", user!.uid, "workout"],
        queryFn: () => getWorkouts(),
        retryDelay: 250,
        retry: 1,
    })
    if (workoutQuery.isLoading) {
        return <div><div><LoadingSpinner /></div></div>
    }
    // Moved useState hook here, outside of any conditional or function block


    const handleDayClick = (day: Date) => {
        setSelectedDay(day);
    };

    const handleCreateWorkout = () => {
        createWorkout();
        queryClient.invalidateQueries({ queryKey: ['workouts', user!.uid, 'workout'] });
    }

    const handleDeleteWorkout = async (workoutId: string) => {
        try {
            await deleteWorkout(workoutId); // Call the delete function
            queryClient.invalidateQueries({ queryKey: ['workouts', user!.uid, 'workout'] });
        } catch (error) {
            console.error('Error deleting workout:', error);
        }
    };

    const workoutCountsByDay = workoutQuery.data?.reduce((acc, workout) => {
        const dateKey = format(new Date(workout.createdAt), 'yyyy-MM-dd'); // Change this line
        acc[dateKey] = (acc[dateKey] || 0) + 1;
        return acc;
    }, {});

    const trailingActions = (workoutID: string) => (
        <TrailingActions>
          <SwipeAction
            destructive={true}
            onClick={() => handleDeleteWorkout(workoutID)}
          >
            
            <span className="text-sm font-bold uppercase text-white pt-2">Delete</span>
            
          </SwipeAction>
        </TrailingActions>
      );

    if (workoutID) {
        return <Outlet />
    }

    return (
        <>
            
                <div className="w-full ">
                    <Calendar
                        onDayClick={handleDayClick}
                        selected={selectedDay}
                        
                        workoutCounts={workoutCountsByDay}
                    />
                </div>
                {workoutQuery.isSuccess && workoutQuery.data ? (
                    <>
                        <div className="w-full">
                            <ScrollArea className="w-full md:w-auto" >
                                <SwipeableList className="w-full"> 
                                    {workoutQuery.data
                                        .filter((workout: any) => {
                                            const workoutDate = new Date(workout.createdAt);
                                            return selectedDay ? workoutDate.toDateString() === selectedDay.toDateString() : true;
                                        })
                                        .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                                        .map((workout: any) => {
                                            const workoutDate = new Date(workout.createdAt);
                                            return (
                                                <SwipeableListItem
                                                    key={workout.id}
                                                    trailingActions={trailingActions(workout.id)}
                                                    className="w-full"
                                                >
                                                    <Link to={`/workouts/${workout.id}`} className="flex items-center justify-between mr-2 ml-2 mb-2 w-full">
                                                        <Card className="flex items-center justify-center w-full p-2 bg-white/30 backdrop-blur-lg text-white">
                                                            <div className="flex flex-row items-center justify-center space-x-2 w-full">
                                                                <span className="font-semibold">{workoutDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).replace(/^0+/, '')}</span>
                                                                {getTimeOfDayIcon(workoutDate)}
                                                            </div>
                                                        </Card>
                                                    </Link>
                                                </SwipeableListItem>
                                            );
                                        })}
                                </SwipeableList>
                            </ScrollArea>
                        </div>

                    </>
                ) : (
                    <div className="w-full text-center">No workouts found</div>
                )}
                <div className="fixed bottom-0 left-0 right-0 pr-2 pl-2 mb-12">
                    <Button onClick={handleCreateWorkout} className="flex items-center justify-center w-full p-2 bg-white/30 backdrop-blur-lg text-white">Add Workout</Button>
                </div>



        </>
    );
};

export default Workouts;
