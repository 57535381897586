"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { useDispatch } from 'react-redux';

import { AppDispatch } from "@/redux/store";

import { createUser, signInUser } from '../../features/authSlice';

import { Button } from "../ui/button"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../ui/tabs"

import {
  Form,
  FormItem,
  FormLabel,
} from "../ui/form"
import { Input } from "../ui/input"
import { Card, CardContent } from "../ui/card"

interface SignUpFormValues {
  email: string;
  password: string;
}


const formSchema = z.object({
  "email": z.string().min(7, {
    message: "email must be at least 7 characters.",
  }),
  "password": z.string().min(8, {
    message: "Password must be at least 8 characters.",
  }),
});

export const SignUpPage = () => {
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      "email": "",
      "password": "",
    },
  })
  const dispatch: AppDispatch = useDispatch();

  // 2. Define a submit handler.
  function onSubmit(actionType: 'create' | 'signIn' | null, values: z.infer<typeof formSchema>) {
    const { email, password } = values;
    if (actionType === 'create') {
      dispatch(createUser({ email, password }));
    } else if (actionType === 'signIn') {
      dispatch(signInUser({ email, password }));
    }
  }

  return (
    <div className="flex items-center justify-center h-[100%]">
      <Tabs defaultValue="signin" className="w-[400px] pr-2 pl-2">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="signin">Sign In</TabsTrigger>
          <TabsTrigger value="create-account">Create Account</TabsTrigger>
        </TabsList>
        <TabsContent value="signin">
          <Card className="w-full pr-2 pl-2">
            <CardContent className="pt-4">
              <Form {...form}>
                <form onSubmit={form.handleSubmit((values) => onSubmit(null, values))} className="space-y-2">
                  {Object.keys(formSchema.shape).map((key) => {
                    return (
                      <FormItem key={key}>
                        <FormLabel htmlFor={key}>{key.split(/(?=[A-Z])/).join(' ').toUpperCase()}</FormLabel>

                        <>
                          <Input id={key} type={key} {...form.register(key as keyof SignUpFormValues)} />
                        </>

                      </FormItem>
                    );
                  })}


                  <Button onClick={() => onSubmit('signIn', form.getValues())}>Sign In</Button>
                </form>
              </Form>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="create-account">
          <Card className="w-full pr-2 pl-2">
            <CardContent className="pt-4">
              <Form {...form} >
                <form onSubmit={form.handleSubmit((values) => onSubmit(null, values))} className="space-y-2">
                  {Object.keys(formSchema.shape).map((key) => {
                    return (
                      <FormItem key={key}>
                        <FormLabel htmlFor={key}>{key.split(/(?=[A-Z])/).join(' ').toUpperCase()}</FormLabel>

                        <>
                          <Input id={key} type={key} {...form.register(key as keyof SignUpFormValues)} />
                        </>

                      </FormItem>
                    );
                  })}

                  <Button onClick={() => onSubmit('create', form.getValues())}>Sign Up</Button>
                </form>
              </Form>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

