import * as React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons"
import { DayPicker, DayProps } from "react-day-picker"

import { cn } from "../../lib/utils"
import { buttonVariants } from "../ui/button"
import { format } from 'date-fns'; 

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  workoutCounts?: Record<string, number>;
};

interface ExtendedDayProps extends DayProps {
  modifiers?: Record<string, boolean>;
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  workoutCounts = {},
  ...props
}: CalendarProps) {
  const workoutDays = Object.keys(workoutCounts).reduce<Record<string, {count: number, marked: boolean}>>((acc, date) => {
    acc[date] = {count: workoutCounts[date], marked: true};
    return acc;
  }, {});



  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      modifiers={{
        workoutDays: (date: Date) => {
          const dateKey = format(date, 'yyyy-MM-dd');
          return workoutDays[dateKey]?.marked ?? false;
        },
      }}
      modifiersClassNames={{
        workoutDays: 'has-workout', // Add your custom class for styling
      }}
      
      classNames={{
        months: "flex flex-col",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium text-white",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 hover:opacity-100 text-white active:bg-white/10 focus:ring-2 focus:ring-ring focus:ring-opacity-50 focus:outline-none focus:ring-accent-foreground focus:ring-offset-background focus:ring-offset-2 focus:ring-offset-opacity-50"
        ),
        nav_button_previous: "absolute left-0",
        nav_button_next: "absolute right-0",
        table: "flex flex-col w-full items-center",
        head_row: "flex",
        head_cell:
          "text-white rounded-md w-8 font-bold text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: cn(
          "relative p-0 text-center text-sm font-bold text-white focus-within:relative focus-within:z-20",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
            : "[&:has([aria-selected])]:rounded-md"
        ),
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-8 w-8 p-0 font-bold text-white aria-selected:opacity-100"
        ),
        day_range_start: "day-range-start",
        day_range_end: "day-range-end",
        day_selected:
          "bg-white/30 backdrop-blur-md text-white hover:bg-white/40 focus:bg-white/40",
        day_today: "bg-transparent border border-white text-white",
        day_outside:
          "day-outside text-white opacity-50 aria-selected:bg-white/30 aria-selected:text-white aria-selected:opacity-30",
        day_disabled: "text-white opacity-50",
        day_range_middle:
          "aria-selected:bg-white/30 aria-selected:text-white",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeftIcon className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronRightIcon className="h-4 w-4" />,
        //render the day content as normal using DayContent
        DayContent: (dayProps: ExtendedDayProps) => {
          const dateKey = format(dayProps.date, 'yyyy-MM-dd');
          const workoutDayInfo = workoutDays[dateKey];
          return (
            <div className="relative flex items-center justify-center">
              {dayProps.date.getDate()}
              {workoutDayInfo && (
                
                <span className="badge rounded-full h-3 w-3 flex items-center justify-center text-white">
                  {workoutDayInfo.count}
                </span>
                
              )}
            </div>
          );
        }
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
