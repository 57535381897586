import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { ProfileSetUp } from '../custom/ProfileSetUp';
import ProfileCard from '../custom/ProfileCard';
import LoadingSpinner from '../custom/LoadingSpinner';

import { getProfile } from '../../features/profileSlice';

interface ProfilePageProps {
  id: string | undefined;
}

const Profile: React.FC<ProfilePageProps> = ({ id }) => {

  const profileQuery = useQuery({
    queryKey: ["profile"],
    queryFn: () => getProfile(id!),
    retryDelay: 250,
    retry: 1,
  })

  if (profileQuery.isLoading) {
    return <div><div><LoadingSpinner /></div></div>
  }

  return (
    profileQuery.isSuccess && profileQuery.data ? (
      <ProfileCard user={profileQuery.data!} />
    ) : (
      <ProfileSetUp />
    )
  );
};

export default Profile;
