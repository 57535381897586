import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createUserWithEmailAndPassword, UserCredential, User, signInWithEmailAndPassword, UserProfile } from 'firebase/auth';

import { auth } from '../firebase';

interface AuthState {
  user: User | null | undefined;
  userProfile: UserProfile | null | undefined;
  loading: boolean;
  error: string | null | undefined;
}

interface CreateUserPayload {
  email: string;
  password: string;
}

export const createUser = createAsyncThunk(
  'auth/createUser',
  async ({ email, password }: CreateUserPayload) => {
    try {
      const userCredential: UserCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user: User = userCredential.user;
      // You can perform additional actions here, such as saving the user to a database
      return user;
    } catch (error) {
      throw error;
    }
  }
);

export const signInUser = createAsyncThunk(
  'auth/signInUser',
  async ({ email, password }: CreateUserPayload) => {
    try {
      const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
      const user: User = userCredential.user;
  
      return user;
    } catch (error) {
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    loading: false,
    error: null,
  } as AuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.user = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }).addCase(signInUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signInUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.user = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default authSlice.reducer;