"use client"

import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import {auth} from "../../firebase";

import {
    Form,
    FormItem,
    FormLabel,
} from "../ui/form"
import { Input } from "../ui/input"
import { Button } from "../ui/button"

import { createProfile } from "../../features/profileSlice"



interface ProfileSetUpFormInfo {
    username: string | null;
    heightFeet?: string | null;
    heightInches?: string | null;
    weightPounds?: string | null;
}


const formSchema = z.object({
    "username": z.string().min(3, {
        message: "email must be at least 3 characters.",
    }),
    "heightFeet": z.string().min(1, {
    }),
    "heightInches": z.string().min(1, {
    }),
    "weightPounds": z.string().min(1, {
    }),
});

export const ProfileSetUp = () => {


    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            "username": "",
        }
    })
    const user = auth.currentUser

    function onSubmit(values: z.infer<typeof formSchema>) {
        if (!user) {
            return;
        }

        if (user) {
            createProfile(user.uid, values);
            
        } else {
            console.log('No authenticated user');
        }
        
    }

    return (
        <div className="flex justify-center items-center h-screen">
        <Form {...form} >
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2 w-full max-w-md">
                {Object.keys(formSchema.shape).map((key) => {
                    return (
                        <FormItem key={key}>
                            <FormLabel htmlFor={key}>{key.split(/(?=[A-Z])/).join(' ').toUpperCase()}</FormLabel>

                            <>
                                <Input id={key} type={key} {...form.register(key as keyof ProfileSetUpFormInfo)} />
                            </>

                        </FormItem>
                    );
                })}

                <Button type="submit">Update Profile</Button>
            </form>
        </Form>
        </div>
    )
}