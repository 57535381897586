import { doc, getDoc, setDoc } from 'firebase/firestore';

import db from '../firebase';

export interface Profile {
  username: string | null;
  heightFeet?: string | null;
  heightInches?: string | null;
  weightPounds?: string | null;
}


export async function createProfile(userId: string, profileData: Profile) {
  const docRef = doc(db, 'profiles', userId);
  await setDoc(docRef, {
      ...profileData
  });
}

export async function getProfile(userId: string ): Promise<Profile | null> {
  //get the current user id of the logged in user
  
  try {
    const docRef = doc(db, 'profiles', userId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return null;
    }

    const data = docSnap.data();
    return {
      username: data?.username || null,
      heightFeet: data?.heightFeet || null,
      heightInches: data?.heightInches || null,
      weightPounds: data?.weightPounds || null,
    };
  } catch (error) {
    console.error('Error getting user profile:', error);
    return null;
  }
}

export const updateProfile = async (userId: string, editedUser: Profile) => {
  if (!userId) throw new Error("User ID is undefined");

  const docRef = doc(db, 'profiles', userId);
  await setDoc(docRef, {
      ...editedUser,
  });
};

