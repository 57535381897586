import { useParams } from 'react-router-dom';
import { useState, useMemo, useRef } from 'react';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { FaRedo } from 'react-icons/fa';
import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";
import { SwipeableList, SwipeableListItem, SwipeAction, TrailingActions } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

import { auth } from '../../firebase';

import { Button } from '../ui/button';
import { Toggle } from '../ui/toggle';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../ui/select';
import { Drawer, DrawerTrigger, DrawerContent, DrawerClose } from '../ui/drawer';
import { Input } from '../ui/input';

import { ScrollArea } from '../ui/scroll-area';

import { ExerciseCard } from '../custom/ExerciseCard';
import LoadingSpinner from '../custom/LoadingSpinner';

import exercises from '../../data/exercises.json';
import { getTemplates, getWorkout, createTemplate, addExercise, addExercises, deleteExercise, Exercise } from '../../features/workoutSlice';


export const Workout = () => {
  const [selectedExercise, setSelectedExercise] = useState({} as Exercise);
  const [numberOfSets, setNumberOfSets] = useState(0);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const { workoutID } = useParams();
  const user = auth.currentUser;
  
  const allBodyParts = useMemo(() => {
    const parts = new Set(exercises.flatMap(exercise => exercise.bodyParts));
    return Array.from(parts);
  }, []);

  const templateNameRef = useRef<HTMLInputElement>(null);

  const individualWorkoutQuery = useQuery({
    queryKey: ["workouts", user!.uid, "workout", workoutID],
    queryFn: () => getWorkout(workoutID!),
    retryDelay: 250,
    retry: 1,
  })

  const templateQuery = useQuery({
    queryKey: ["workouts", user!.uid, "templates"],
    queryFn: () => getTemplates(),
    retryDelay: 250,
    retry: 1,
  })

  const addExerciseMutation = useMutation({
    mutationKey: [workoutID, 'addExercise', selectedExercise.pfxExerciseId],
    mutationFn: () => addExercise(workoutID!, selectedExercise, numberOfSets, user!.uid),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workouts', user!.uid, 'workout'] });
      setSelectedExercise({} as Exercise);
      setNumberOfSets(0);
    },
  });

  const handleAddExercise = () => {
    addExerciseMutation.mutate();
  };

  const addExercisesMutation = useMutation({
    mutationKey: [workoutID, 'addExercises'],
    mutationFn: (templateExercises: Exercise[]) => addExercises(workoutID!, templateExercises, user!.uid),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workouts', user!.uid, 'workout'] });
    },
  });

  const handleAddExercisesFromTemplate = () => {
    const selectedTemplate = templateQuery.data?.find(template => template.name === selectedTemplateId);
    if (selectedTemplate && selectedTemplate.exercises) {
      addExercisesMutation.mutate(selectedTemplate.exercises);
    }
  };

  const deleteExerciseMutation = useMutation({
    mutationKey: [workoutID, 'deleteExercise'],
    mutationFn: (exerciseId: string) => deleteExercise(workoutID!, exerciseId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workouts', user!.uid, 'workout'] });
    },
  });

  const handleDeleteExercise = async (exerciseId: string) => {
    deleteExerciseMutation.mutate(exerciseId);
  };

  
  const handleCreateTemplate = () => {
    const templateName = templateNameRef.current?.value;
    if (templateName && workoutID) {
      createTemplate(workoutID, templateName, individualWorkoutQuery.data?.exercises);
    }
  };

  const handleExerciseChange = (exerciseId: string) => {
    const exercise = exercises.find(ex => ex.pfxExerciseId === exerciseId);
    if (exercise) {
      setSelectedExercise(exercise);
    }
  };

  const handleSetsChange = (sets: number) => { 
    setNumberOfSets(sets);
  };

 
  const trailingActions = (exerciseId: string) => (
    <TrailingActions>
      <SwipeAction
        destructive={true}
        onClick={() => handleDeleteExercise(exerciseId)}
      >
        <span className="text-sm font-bold uppercase text-white pt-2">Delete</span>
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <div >

      {individualWorkoutQuery.isLoading && <div><LoadingSpinner /></div>}

      {individualWorkoutQuery.isSuccess && individualWorkoutQuery.data && (

        <ScrollArea className="w-full md:w-auto pb-36">


          <SwipeableList >
            {individualWorkoutQuery.data.exercises.map((exercise: Exercise) => {
              return (
                <SwipeableListItem key={exercise.pfxExerciseId} trailingActions={trailingActions(exercise.pfxExerciseId)}>
                  <ExerciseCard exercise={exercise} workoutID={workoutID} />
                </SwipeableListItem>
              )
            })}
          </SwipeableList>
        </ScrollArea>

      )}


      <Drawer >
        <DrawerTrigger asChild>
          <div className="fixed bottom-0 left-0 right-0 pr-2 pl-2 mb-24">
            <Button className="flex items-center justify-center w-full p-2 bg-white/30 backdrop-blur-lg text-white">Add Exercise</Button>
          </div>
        </DrawerTrigger>
        <DrawerContent className="p-4">
          <div className="flex flex-wrap gap-3 mb-4">
            {allBodyParts.map((part) => (
              <Toggle
                key={part}
                pressed={activeFilters.includes(part)}
                onPressedChange={() => {
                  setActiveFilters(current =>
                    current.includes(part)
                      ? current.filter(filter => filter !== part)
                      : [...current, part]
                  );
                }}
                className="capitalize text-xs py-1 px-2 mt-2 text-white"
                size='sm'
              >
                {part}
              </Toggle>
            ))}
          </div>

          <Select onValueChange={handleExerciseChange}>
            <SelectTrigger aria-label="Exercise" className="w-full">
              <SelectValue placeholder="Select an exercise" />
            </SelectTrigger>
            <SelectContent >
              {exercises
                .filter(exercise =>
                  activeFilters.length === 0 || exercise.bodyParts.some(part => activeFilters.includes(part))
                )
                .map((exercise, index) => (
                  <SelectItem key={index} value={exercise.pfxExerciseId} className="hover:bg-gray-100">{exercise.name}</SelectItem>
                ))}
            </SelectContent>
          </Select>



          <div className="flex items-center justify-between p-4 rounded-lg">
            <Button size="icon" className="h-10 w-10 shrink-0 rounded-full" onClick={() => handleSetsChange(Math.max(0, (numberOfSets || 0) - 1))}>
              <MinusIcon className="text-white" />
            </Button>
            <div className="flex flex-col items-center">
              <span className="text-3xl font-semibold text-white">{numberOfSets || 0}</span>
              <div className="flex items-center text-sm font-medium text-white">
                <span>SETS</span>
                <FaRedo className="ml-1" />
              </div>
            </div>
            <Button size="icon" className="h-10 w-10 shrink-0 rounded-full" onClick={() => handleSetsChange(Math.max(0, (numberOfSets || 0) + 1))}>
              <PlusIcon className="text-white" />
            </Button>
          </div>
          <DrawerClose asChild>
            <Button onClick={handleAddExercise} className="font-bold py-2 px-4 rounded">Add</Button>
          </DrawerClose>
        </DrawerContent>
      </Drawer>

      <div className="fixed bottom-0 left-0 right-0 pr-2 pl-2 mb-12 flex justify-between">
        <Drawer>
          <DrawerTrigger asChild>
            <Button className="flex-1 items-center justify-center p-2 bg-white/30 backdrop-blur-lg text-white mr-2">Add Exercises from Template</Button>
          </DrawerTrigger>
          <DrawerContent className="p-4">
            <Select onValueChange={(templateId) => setSelectedTemplateId(templateId)}>
              <SelectTrigger aria-label="Template" className="w-full mt-2">
                <SelectValue placeholder="Select a Template" />
              </SelectTrigger>
              <SelectContent >
                {templateQuery.data?.map((template, index) => (
                  <SelectItem key={index} value={template.name} className="hover:bg-gray-100">{template.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
            <DrawerClose asChild>
              <Button onClick={handleAddExercisesFromTemplate} className="flex items-center justify-center w-full p-2 bg-white/30 backdrop-blur-lg text-white mt-2">Add Exercises</Button>
            </DrawerClose>
          </DrawerContent>

        </Drawer>
        <Drawer>
          <DrawerTrigger asChild>
            <Button className="flex-1 items-center justify-center p-2 bg-white/30 backdrop-blur-lg text-white">Create Template</Button>
          </DrawerTrigger>
          <DrawerContent className="p-4 text-white">
            NAME
            <Input ref={templateNameRef} key='name' className="text-lg h-12" />
            <DrawerClose asChild>
              <Button onClick={handleCreateTemplate} className="flex items-center justify-center w-full p-2 bg-white/30 backdrop-blur-lg text-white mt-2">Create Template</Button>
            </DrawerClose>
          </DrawerContent>

        </Drawer>
      </div>

    </div>
  );
};
