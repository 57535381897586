import React from 'react';
import { styled } from '@stitches/react';
import * as MenubarPrimitive from '@radix-ui/react-menubar';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';

import { auth } from '../../firebase';

import { Menubar, MenubarMenu } from '../ui/menubar';

import Profile from '../custom/Profile';
import Workouts from '../custom/Workouts';
import { Workout } from '../custom/Workout';

const StyledTrigger = styled(MenubarPrimitive.Trigger, {
  borderRadius: '4px',
  padding: '0 15px',
  alignItems: 'center',
  justifyContent: 'center',
});

const AppContent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = auth.currentUser;

  const handleMenuClick = (route: string) => {
    navigate(route);
  };

  // Function to check if the route is active
  const isActive = (route: string) => location.pathname.startsWith(route);

  return (
    <>
     
        <Routes>
          <Route path="/profile" element={<Profile id={user?.uid} />} />
          <Route path="/workouts" element={<Workouts />} >
            <Route path=":workoutID" element={<Workout />} />
          </Route>
          {/* define other routes here */}
        </Routes>
        <Menubar className="fixed bottom-2 left-0 right-0 ml-1 mr-1">
          <MenubarMenu>
            <MenubarPrimitive.Root>
              <MenubarPrimitive.Trigger asChild>
                <StyledTrigger onClick={() => handleMenuClick('/profile')} data-state={isActive('/profile') ? 'active' : 'inactive'}>
                  Profile
                </StyledTrigger>
              </MenubarPrimitive.Trigger>
              <MenubarPrimitive.Trigger asChild>
                <StyledTrigger onClick={() => handleMenuClick('/workouts')} data-state={isActive('/workouts') ? 'active' : 'inactive'}>
                  Workouts
                </StyledTrigger>
              </MenubarPrimitive.Trigger>
            </MenubarPrimitive.Root>
          </MenubarMenu>
        </Menubar>
      
      
      </>
  );
};

export default AppContent;