// src/App.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import AppContent from './components/custom/AppContent';
import './App.css';
import { SignUpPage } from "./components/custom/SignUp";

interface RootState {
  user: {
    id: number;
    email: string;
    // other properties of user...
  };
  // other state properties...
  auth: {
    user: {
      uid: string;
    };
    // other auth state properties...
  };
}

function App() {
  const user = useSelector((state: RootState) => state.auth.user);

  return (
    user ? 
    <AppContent /> : <SignUpPage />
  );
}

export default App;