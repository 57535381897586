import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Card, CardHeader, CardFooter } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from "../ui/input";
import { Drawer, DrawerTrigger, DrawerContent, DrawerClose } from '../ui/drawer';
import { updateProfile, Profile } from '../../features/profileSlice';
import {auth} from "../../firebase";


interface ProfileCardProps {
    user: Profile;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ user }) => {
    const [editedUser, setEditedUser] = useState<Profile>(user);
    const queryClient = useQueryClient();
    const currentUser = auth.currentUser;

    const updateProfileMutation = useMutation({
        mutationKey: ['editProfile'],
        mutationFn: (editedUser: Profile) => updateProfile(currentUser!.uid ?? '', editedUser),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['profile'] });
        },
    });

    const handleSaveClick = () => {
        updateProfileMutation.mutate(editedUser);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditedUser(prevUser => ({
            ...prevUser,
            [name]: value,
        }));
    };

    return (
        <>
            <div className="flex z-0 justify-center items-center h-[80vh]">
                <Card className="m-2">
                    <CardHeader>
                        <div className="text-center">
                            <h2 className="text-xl font-bold text-white">{user.username}</h2>
                            <p className="text-sm text-white font-bold">{user.heightFeet}'{user.heightInches}" - {user.weightPounds} lbs</p>
                        </div>
                    </CardHeader>
                </Card>
            </div>

            <Drawer>
                <DrawerTrigger asChild>
                    <div className="fixed bottom-0 left-0 right-0 pr-2 pl-2 mb-12">
                        <Button className="flex items-center justify-center w-full p-2 bg-white/30 backdrop-blur-lg text-white">Edit</Button>
                    </div>
                </DrawerTrigger>
                <DrawerContent className="p-4">
                    <div className="space-y-4 pt-2 pb-2">
                        <Input
                            className="bg-white/10 text-white text-lg h-12"
                            type="text"
                            name="username"
                            placeholder="Username"
                            value={editedUser.username || ''}
                            onChange={handleInputChange}
                        />
                        <div className="flex space-x-2">
                            <Input
                                className="bg-white/10 text-white w-1/2 text-lg h-12"
                                type="text"
                                name="heightFeet"
                                placeholder="Height (Feet)"
                                value={editedUser.heightFeet || ''}
                                onChange={handleInputChange}
                            />
                            <Input
                                className="bg-white/10 text-white w-1/2 text-lg h-12"
                                type="text"
                                name="heightInches"
                                placeholder="Height (Inches)"
                                value={editedUser.heightInches || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <Input
                            className="bg-white/10 text-white text-lg h-12"
                            type="text"
                            name="weightPounds"
                            placeholder="Weight (Pounds)"
                            value={editedUser.weightPounds || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <CardFooter className="flex justify-center space-x-2">

                        <DrawerClose asChild>
                            <Button size="sm" onClick={handleSaveClick} className="flex items-center justify-center w-full p-2 bg-white/30 backdrop-blur-lg text-white">
                                Save
                            </Button>
                        </DrawerClose>
                    </CardFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default ProfileCard;