import { doc, setDoc } from 'firebase/firestore';
import db, { auth } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import {
  getDocs,
  collection,
  getDoc,
  deleteDoc,
  arrayUnion
} from 'firebase/firestore';

export interface Exercise {
  pfxExerciseId: string;
  name: string;
  experienceLevel: string;
  equipment: string;
  bodyParts: string[];
  numberOfSets?: number;
}

export interface SetRecording {
  id: string;
  reps: number;
  weight: number;
  status: string;
  createdAt?: string;
}

interface WorkoutSnippet {
  id: string;
  createdAt: string;
}

//CRUD
export async function createWorkout() {
  const currentUser = auth.currentUser;
  //path on docRef should be workouts/uid/workout
  const path = `workouts/${currentUser!.uid}/workout`;
  const docRef = doc(db, path, uuidv4());
  const workout = {
    id: docRef.id,
    userId: currentUser?.uid,
    exercises: [],
    createdAt: new Date().toISOString()
  };
  await setDoc(docRef, {
    ...workout
  });
}

export async function getWorkout(workoutId: string) {
  const currentUser = auth.currentUser;
  const docRef = doc(db, 'workouts', currentUser!.uid, 'workout', workoutId);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();

  return data;
}

export const deleteWorkout = async (workoutId: string) => {
  const currentUser = auth.currentUser;
  const docRef = doc(db, 'workouts', currentUser!.uid, 'workout', workoutId);
  const setRecodingColRef = collection(
    db,
    'workouts',
    currentUser!.uid,
    'workout',
    workoutId,
    'set_recordings'
  );
  //delete the subcollection
  const querySnapshot = await getDocs(setRecodingColRef);
  querySnapshot.forEach((doc) => {
    deleteDoc(doc.ref);
  });
  //delete the workout
  await deleteDoc(docRef);
};

export async function getWorkouts() {
  const currentUser = auth.currentUser;
  const path = `workouts/${currentUser!.uid}/workout`;

  const querySnapshot = await getDocs(collection(db, path));
  const workouts = querySnapshot.docs.map((doc) => doc.data());

  return workouts;
}

export const addExercise = async (
  workoutID: string,
  exercise: Exercise,
  numberOfSets: number,
  userUid: string
) => {
  if (workoutID && exercise.pfxExerciseId && userUid) {
    const docRef = doc(db, 'workouts', userUid, 'workout', workoutID);
    const setsArray = Array.from({ length: numberOfSets }, () => uuidv4());
    const setRecordingsRef = doc(
      db,
      'workouts',
      userUid,
      'workout',
      workoutID,
      'set_recordings',
      exercise.pfxExerciseId
    );
    await setDoc(
      docRef,
      {
        exercises: arrayUnion({ ...exercise, numberOfSets: numberOfSets }) // Include numberOfSets in the exercise information
      },
      { merge: true }
    );
    await setDoc(
      setRecordingsRef,
      {
        sets: setsArray.map((setId) => ({
          id: setId,
          reps: 0,
          weight: 0,
          status: 'incomplete'
        }))
      },
      { merge: true }
    );
  } else {
    console.error('workoutID is undefined');
  }
};

export async function getExerciseHistory(
  workoutID: string,
  pfxExerciseId: string
) {
  const currentUser = auth.currentUser;
  const workoutsPath = `workouts/${currentUser!.uid}/workout`;
  const workoutsRef = collection(db, workoutsPath);
  //log each workout in workoutsRef
  const querySnapshot1 = await getDocs(workoutsRef);
  const workoutsWithExercise: WorkoutSnippet[] = [];
  const setRecordings: SetRecording[] = [];

  querySnapshot1.forEach((doc) => {
    //set exercises of the workout
    const exercises = doc.data().exercises;
    //check the exercises for the pfxExerciseId
    if (doc.id !== workoutID) {
      exercises.forEach((exercise: any) => {
        if (exercise.pfxExerciseId === pfxExerciseId) {
          //add doc.id to the workotusWithExercise array
          workoutsWithExercise.push({id: doc.id, createdAt: doc.data().createdAt});
        }
      });
    }
  });

  //use the workoutsWithExercise array to get the set recordings for the exercise

  for (const workout of workoutsWithExercise) {
    const setRecordingsRef = collection(
      db,
      'workouts',
      currentUser!.uid,
      'workout',
      workout.id,
      'set_recordings'
    );
    //log each set recording in setRecordingsRef
    const querySnapshot2 = await getDocs(setRecordingsRef);
    querySnapshot2.forEach((doc) => {
      const data = doc.data();
      const sets = data.sets;
      //check the sets for the pfxExerciseId
      if (doc.id === pfxExerciseId) {
        sets.forEach((set: any) => {
          //convert the id to a date and add to the set object these are 
          const date = workout.createdAt;
          set.createdAt = date;
          setRecordings.push(set);
        });
      }
      
    });
  }
  


  return setRecordings.flat();
}

export const deleteExercise = async (workoutId: string, exerciseId: string) => {
  //this needs to grab the workout and remove the exercise from the array
  //it should also remove the set recordings for that exercise
  const currentUser = auth.currentUser;
  const docRef = doc(db, 'workouts', currentUser!.uid, 'workout', workoutId);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  if (data) {
    const exercises = data.exercises;
    const newExercises = exercises.filter(
      (exercise: any) => exercise.pfxExerciseId !== exerciseId
    );
    await setDoc(docRef, {
      ...data,
      exercises: newExercises
    });
    const setRecodingColRef = collection(
      db,
      'workouts',
      currentUser!.uid,
      'workout',
      workoutId,
      'set_recordings'
    );
    //we only want to delete the set recordings for the exercise we are removing
    const querySnapshot = await getDocs(setRecodingColRef);
    querySnapshot.forEach((doc) => {
      if (doc.data().exerciseId === exerciseId) {
        deleteDoc(doc.ref);
      }
    });
  }
};

export const addExercises = async (
  workoutID: string,
  templateExercises: Exercise[],
  userUid: string
) => {
  if (workoutID && userUid) {
    const docRef = doc(db, 'workouts', userUid, 'workout', workoutID);
    // Loop through each exercise in the template
    for (const exercise of templateExercises) {
      const setsArray = Array.from({ length: exercise.numberOfSets || 0 }, () =>
        uuidv4()
      );
      const setRecordingsRef = doc(
        db,
        'workouts',
        userUid,
        'workout',
        workoutID,
        'set_recordings',
        exercise.pfxExerciseId
      );
      await setDoc(
        docRef,
        {
          exercises: arrayUnion({ ...exercise }) // Add exercise to the workout
        },
        { merge: true }
      );
      await setDoc(
        setRecordingsRef,
        {
          sets: setsArray.map((setId) => ({
            id: setId,
            reps: 0,
            weight: 0,
            status: 'incomplete'
          }))
        },
        { merge: true }
      );
    }
  } else {
    console.error('workoutID or userUid is undefined');
  }
};

export async function createTemplate(
  workoutID: string,
  templateName: string,
  exercises: Exercise[]
) {
  const currentUser = auth.currentUser;
  const templateRef = doc(
    db,
    'workouts',
    currentUser!.uid,
    'templates',
    workoutID
  );
  await setDoc(templateRef, {
    name: templateName,
    exercises: exercises
  });
}

export async function getTemplates() {
  const currentUser = auth.currentUser;
  const path = `workouts/${currentUser!.uid}/templates`;
  const querySnapshot = await getDocs(collection(db, path));
  const templates = querySnapshot.docs.map((doc) => doc.data());

  return templates;
}
