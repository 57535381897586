import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { doc, setDoc, getDoc, collection } from 'firebase/firestore';
import { MinusIcon, PlusIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { Bar, ResponsiveContainer, Line, Legend, ComposedChart } from 'recharts';
import db, { auth } from '../../firebase';
import { Card, CardHeader, CardContent, CardFooter } from '../ui/card';
import { Tabs, TabsContent } from "../ui/tabs";
import { Button } from '../ui/button';
import { Collapsible, CollapsibleContent } from '../ui/collapsible';
import { Drawer, DrawerTrigger, DrawerContent, DrawerClose } from '../ui/drawer';
import { Timer } from '../custom/Timer';
import { Exercise, getExerciseHistory, SetRecording } from '../../features/workoutSlice';
import { FaCheckCircle, FaTimesCircle, FaDumbbell, FaRedo } from 'react-icons/fa';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../ui/select";
import { v4 as uuidv4 } from 'uuid'; // Import UUID to generate unique IDs for sets

import triceps from '../../icons/012-muscles-1.png';
import shoulders from '../../icons/002-shoulder.png';
import back from '../../icons/004-back-1.png';
import core from '../../icons/014-human.png';
import chest from '../../icons/005-gym.png';
import legs from '../../icons/011-front-1.png'



interface ExerciseCardProps {
    exercise: Exercise;
    workoutID?: string; // Add this line
}

interface SetDetails {
    id: string;
    reps?: number;
    weight?: number;
    status?: 'completed' | 'incomplete';
    timeSeconds?: number;
}


const bodyPartIcon = (part: string) => {
    switch (part) {
        case 'ARMS':
            return <img src={triceps} alt="triceps" className='h-[16px]' />;
        case 'LEGS':
            return <img src={legs} alt="legs" className='h-[16px]' />;
        case 'CORE':
            return <img src={core} alt="core" className='h-[16px]' />;
        case 'FULL_BODY':
        case 'CHEST':
            return <img src={chest} alt="chest" className='h-[16px]' />;
        case 'BACK':
            return <img src={back} alt="back" className='h-[16px]' />;
        case 'SHOULDERS':
            return <img src={shoulders} alt="shoulders" className='h-[16px]' />;
        default:
            return null;
    }
};

export const ExerciseCard: React.FC<ExerciseCardProps> = ({ exercise, workoutID }) => {
    const [sets, setSets] = useState<SetDetails[]>([]);
    const [timerSeconds, setTimerSeconds] = useState<number>(0);
    const [timerIsActive, setTimerIsActive] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string | undefined>(undefined); // State to manage active tab
    const [weightIncrement, setWeightIncrement] = useState<number>(1);
    const queryClient = useQueryClient();
    const user = auth.currentUser;
    const [exerciseHistory, setExerciseHistory] = useState<SetRecording[]>([]);

    const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);

    // Function to toggle collapsible state
    const toggleCollapsible = () => setIsCollapsibleOpen(!isCollapsibleOpen);
    const isActivity = exercise.pfxExerciseId.includes('activities');


    const handleInputChange = (setId: string, field: 'reps' | 'weight', value: number) => {
        const updatedSets = sets.map(set => set.id === setId ? { ...set, [field]: value } : set);
        setSets(updatedSets);
    };

    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(e.target.value);
        setWeightIncrement(newValue);
    };
    const copyPreviousSetDetails = () => {
        const activeSetIndex = sets.findIndex(set => set.id === activeTab);
        if (activeSetIndex > 0) { // Ensure there is a previous set
            const previousSet = sets[activeSetIndex - 1];
            const updatedSets = sets.map((set, index) => {
                if (index === activeSetIndex) {
                    return { ...set, reps: previousSet.reps, weight: previousSet.weight };
                }
                return set;
            });
            setSets(updatedSets);
        }
    };

    const fetchSetRecordings = async () => {
        if (workoutID && user) {
            const setRecordingsRef = doc(db, 'workouts', user.uid, 'workout', workoutID, 'set_recordings', exercise.pfxExerciseId);
            const docSnap = await getDoc(setRecordingsRef);
            if (docSnap.exists()) {
                return docSnap.data().sets as SetDetails[];
            } else {
                console.log("No such document!");
                return [];
            }
        }
        return [];
    };

    const { data: setRecordings } = useQuery({
        queryKey: ['setRecordings', workoutID, exercise.pfxExerciseId],
        queryFn: fetchSetRecordings,
        enabled: !!workoutID,
    });
    useEffect(() => {
        const fetchHistory = async () => {
            const historyData = await getExerciseHistory(workoutID!, exercise.pfxExerciseId);
            setExerciseHistory(historyData);
        };

        fetchHistory();
    }, [workoutID, exercise.pfxExerciseId]);




    useEffect(() => {
        if (setRecordings) {
            setSets(setRecordings);
        }
    }, [setRecordings]);


    const handleRecordSet = async (setId: string) => {
        const updatedSets = sets.map(set => set.id === setId ? { ...set, status: 'completed', timeSeconds: timerSeconds } : set);
        if (user && workoutID) {
            const setRecordingsRef = doc(db, 'workouts', user.uid, 'workout', workoutID, 'set_recordings', exercise.pfxExerciseId);
            await setDoc(setRecordingsRef, { sets: updatedSets }, { merge: true });
            queryClient.invalidateQueries({ queryKey: ['setRecordings', workoutID] });
        }
    };

    const handleAddSet = () => {
        const newSet: SetDetails = { id: uuidv4(), reps: 0, weight: 0, status: 'incomplete', timeSeconds: 0 };
        if (user && workoutID) {
            setSets(prevSets => [...prevSets, newSet]);
            setActiveTab(newSet.id); // Automatically switch to the new set tab
            const setRecordingsRef = collection(db, 'workouts', user.uid, 'workout', workoutID, 'set_recordings');
            const docRef = doc(setRecordingsRef, exercise.pfxExerciseId);
            getDoc(docRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const existingSets = docSnap.data().sets || [];
                    const updatedSets = [...existingSets, newSet];
                    setDoc(docRef, { sets: updatedSets }, { merge: true }).then(() => {
                        queryClient.invalidateQueries({ queryKey: ['setRecordings', workoutID] });
                    });
                } else {
                    setDoc(docRef, { sets: [newSet] }, { merge: true }).then(() => {
                        queryClient.invalidateQueries({ queryKey: ['setRecordings', workoutID] });
                    });
                }
            }).catch((error) => {
                console.error("Error adding document: ", error);
            });
        }
    };

    return (
        <Card className=" overflow-hidden mb-1 ml-2 mr-2 mt-2 w-full">
            <CardHeader className="pb-2 pt-2">
                <div className="flex justify-between pb-0 pt-0">
                    <div className="flex flex-row items-center space-x-2">
                        <span className="font-bold text-md text-white">{exercise.name}</span>
                        {/* <div className="flex space-x-1">
                            {exercise.bodyParts.map((part) => (
                                <span key={part} className="inline-block">{bodyPartIcon(part)}</span>
                            ))}
                        </div> */}
                    </div>
                    <Button size="icon" className="h-10 w-10 shrink-0 rounded-full" onClick={toggleCollapsible}>
                        <ChevronDownIcon className="text-white" />
                    </Button>
                </div>
            </CardHeader>
            <CardContent className="overflow-auto pb-0">
                <Collapsible open={isCollapsibleOpen}>

                    <CollapsibleContent>
                        <div >
                           
                                <span className="text-sm font-bold tracking-tighter mr-1 text-white">
                                    {sets.length}
                                </span>
                                <span className="text-sm uppercase text-white mr-2">
                                    sets
                                </span>
                                <span className="text-sm font-bold tracking-tighter mr-1 text-white">
                                    {new Date(sets.reduce((total, set) => total + (set.timeSeconds || 0), 0) * 1000).toISOString().substr(14, 5)}
                                </span>
                                <span className="text-sm uppercase text-white">
                                    total exercise time
                                </span>

                            


                        </div>
                        <Tabs value={activeTab} onValueChange={setActiveTab}>
                            <div className="flex flex-col">
                                <div className="flex justify-between items-center flex-row pb-2">
                                    <Select value={activeTab} onValueChange={setActiveTab}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select a set" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {sets.map((set, index) => (
                                                <SelectItem key={set.id} value={set.id}>
                                                    Set {index + 1} {set.status === 'completed' ? <FaCheckCircle className="inline ml-2 text-green-500" /> : <FaTimesCircle className="inline ml-2 text-red-500" />}
                                                    {set.timeSeconds && (
                                                        <span className="inline text-sm ml-2">{new Date(set.timeSeconds * 1000).toISOString().substr(14, 5)}</span>
                                                    )}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <Button size="icon" className="h-10 w-10 shrink-0 rounded-full ml-2" onClick={handleAddSet}>
                                        <PlusIcon className="text-white" />
                                    </Button>


                                </div>



                                {sets.map((set) => (
                                    activeTab === set.id &&
                                    <TabsContent key={set.id} value={set.id}>
                                        <div className="flex justify-around">

                                            <Drawer>
                                                <DrawerTrigger asChild>
                                                    {!isActivity && <div className="cursor-pointer text-center">
                                                        <div className="text-7xl font-bold tracking-tighter text-white">
                                                            {set.reps}
                                                        </div>
                                                        <div className="text-[0.70rem] uppercase text-white">
                                                            reps  <FaRedo className="inline ml-1" />
                                                        </div>
                                                    </div>}

                                                </DrawerTrigger>
                                                <DrawerContent className="p-[5px]">
                                                    <div className="flex items-center justify-between p-4 rounded-lg">
                                                        <Button size="icon" className="h-10 w-10 shrink-0 rounded-full" onClick={() => handleInputChange(set.id, 'reps', Math.max(0, (set.reps || 0) - 1))}>
                                                            <MinusIcon className="text-white" />
                                                        </Button>
                                                        <div className="flex flex-col items-center">
                                                            <span className="text-3xl font-semibold text-white">{set.reps || 0}</span>
                                                            <div className="flex items-center text-sm font-medium text-white">
                                                                <span>REPS</span>
                                                                <FaRedo className="ml-1" />
                                                            </div>
                                                        </div>
                                                        <Button size="icon" className="h-10 w-10 shrink-0 rounded-full" onClick={() => handleInputChange(set.id, 'reps', (set.reps || 0) + 1)}>
                                                            <PlusIcon className="text-white" />
                                                        </Button>
                                                    </div>
                                                    <DrawerClose asChild>
                                                        <Button >Add</Button>
                                                    </DrawerClose>
                                                </DrawerContent>
                                            </Drawer>
                                            <Drawer>
                                                <DrawerTrigger asChild>
                                                    {!isActivity && <div className="cursor-pointer text-center text-white">
                                                        <div className="text-7xl font-bold tracking-tighter">
                                                            {set.weight}
                                                        </div>
                                                        <div className="text-[0.70rem] uppercase text-white">
                                                            weight <FaDumbbell className="inline ml-1" />
                                                        </div>
                                                    </div>}

                                                </DrawerTrigger>
                                                <DrawerContent className="p-[5px]">
                                                    <div className="flex items-center justify-between p-4 rounded-lg">
                                                        <Button size="icon" className="h-10 w-10 shrink-0 rounded-full" onClick={() => handleInputChange(set.id, 'weight', Math.max(0, (set.weight || 0) - weightIncrement))}>
                                                            <MinusIcon className="text-white" />
                                                        </Button>
                                                        <div className="flex flex-col items-center">
                                                            <span className="text-3xl font-semibold text-white">{set.weight || 0}</span>
                                                            <div className="flex items-center text-sm font-medium text-white">
                                                                <span>WEIGHT</span>
                                                                <FaDumbbell className="ml-1" />
                                                            </div>
                                                        </div>
                                                        <Button size="icon" className="h-10 w-10 shrink-0 rounded-full" onClick={() => handleInputChange(set.id, 'weight', (set.weight || 0) + weightIncrement)}>
                                                            <PlusIcon className="text-white" />
                                                        </Button>
                                                    </div>
                                                    <div className="flex items-center justify-center">
                                                        <label className="text-sm font-medium text-white">Increment by {weightIncrement}</label>
                                                    </div>
                                                    <div className="flex items-center pl-10 pr-10 pt-2 pb-2">
                                                        <input
                                                            type="range"
                                                            min="1"
                                                            max="10"
                                                            step="1"
                                                            value={weightIncrement}
                                                            onChange={handleSliderChange}
                                                            className="w-full"
                                                        />
                                                    </div>
                                                    <DrawerClose asChild>
                                                        <Button >Add</Button>
                                                    </DrawerClose>
                                                </DrawerContent>
                                            </Drawer>
                                        </div>
                                        <div className="flex flex-row justify-center items-center pt-2 pb-2">
                                            <Timer
                                                seconds={timerSeconds}
                                                setSeconds={setTimerSeconds}
                                                isActive={timerIsActive}
                                                setIsActive={setTimerIsActive}
                                            />
                                        </div>
                                        <div className="flex flex-row justify-between items-center w-full pb-4">
                                            {!isActivity && <Button className="pr-2" onClick={copyPreviousSetDetails}>Use Previous Reps & Weight</Button>}
                                            <Button onClick={() => handleRecordSet(set.id)}>Record Set</Button>
                                        </div>

                                    </TabsContent>
                                ))}
                                {exerciseHistory.length > 0 && <div className="w-full h-[150px] pt-2 pb-2">
                                    <label className="text-sm font-medium text-white">Set History</label>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart data={exerciseHistory.sort((a, b) => new Date(a.createdAt || 0).getTime() - new Date(b.createdAt || 0).getTime())}>

                                            <Bar dataKey="reps" fill="#8884d8" label={{ position: 'insideBottom', fill: "#ffffff", value: 'Previous Reps' }} />
                                            <Line type="natural" dataKey="weight" stroke="#82ca9d" label={{ position: 'bottom', fill: "#ffffff" }} />
                                            <Legend verticalAlign="bottom" height={12} />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>}

                            </div>
                        </Tabs>
                    </CollapsibleContent>
                </Collapsible>
            </CardContent>
            
        </Card>
    );
};
